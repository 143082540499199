var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-container" },
    [
      _c("div", { staticClass: "post-info" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.translateTitle(
                "首页图展样式配置在组件添加时设定，可以在组件配置中二次编辑"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "post-advertising-space" },
        [
          _c("span", [_vm._v(_vm._s(_vm.translateTitle("广告位")))]),
          _vm._l(_vm.sliderList, function (item, index) {
            return [
              _vm.$route.path.includes(item.path)
                ? _c("el-image", { key: index, attrs: { src: item.image } })
                : _vm._e(),
            ]
          }),
          _c("p", [_vm._v(_vm._s(_vm.translateTitle(_vm.$route.query.title)))]),
        ],
        2
      ),
      _c("Form", {
        key: _vm.value,
        attrs: { column: _vm.column, "label-width": 130, query: _vm.params },
        on: {
          handleCheckLink: _vm.handleCheckLink,
          handleImageUrl: _vm.handleImageUrl,
          handleVideoUrl: _vm.handleVideoUrl,
        },
      }),
      _c(
        "div",
        { staticClass: "post-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "medium", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("取消")) + " ")]
          ),
        ],
        1
      ),
      _c("CheckLinkDialog", {
        ref: "checklink",
        on: { handleLink: _vm.handleLink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }