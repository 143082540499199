const config = {
  loading: false,
  sliderList: Object.freeze([
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      desc: '4.3之前版本首页',
      path: 'version',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      desc: '频道页：附近频道',
      path: 'nearbyChannels',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      desc: '频道页：精彩推荐',
      path: 'recommend',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      desc: '频道页：内容',
      path: 'content',
    },
    {
      image: require('@/assets/images/operate/appindex_cnmp_weather.png'),
      desc: '发现页',
      path: 'find',
    },
    {
      image: require('@/assets/images/operate/operation_spread_index_floating_gray.png'),
      name: '首页浮动广告',
      path: 'homeFloat',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      name: '文章详情幻灯',
      path: 'articleDetailsSlide',
    },
    {
      image: require('@/assets/images/operate/operation_spread_slider_gray.png'),
      name: '主题详情顶部广告（发布者下方）',
      path: 'detailsTop',
    },
  ]),
  value: '0',
  phoneConfigList: Object.freeze([
    {
      id: 'ac_ia',
      label: '苹果审核版显示',
    },
    {
      id: 'ac_in',
      label: '苹果普通版显示',
    },
    {
      id: 'ac_aa',
      label: '安卓审核版显示',
    },
    {
      id: 'ac_an',
      label: '安卓普通版显示',
    },
  ]),
  advertisingTypeList: [],
  // 是否
  whetherList1: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 0,
      label: '否',
    },
  ]),
  // 是否
  whetherList2: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  // 是否
  typeList: Object.freeze([
    {
      id: 1,
      label: '图片',
    },
    {
      id: 2,
      label: '视频',
    },
  ]),
  // 视频上传类型
  videoTypeList: Object.freeze([
    {
      id: 1,
      label: '视频路径',
    },
    {
      id: 2,
      label: '本地上传',
    },
  ]),
}

export default config
