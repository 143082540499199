<template>
  <div class="post-container">
    <div class="post-info">
      {{
        translateTitle(
          '首页图展样式配置在组件添加时设定，可以在组件配置中二次编辑'
        )
      }}
      <!-- <a href="http://help_for_magapp.mydoc.io/?t=173186/" target="_blank">
        {{ translateTitle('查看示例') }}
      </a> -->
    </div>
    <div class="post-advertising-space">
      <span>{{ translateTitle('广告位') }}</span>
      <template v-for="(item, index) in sliderList">
        <el-image
          v-if="$route.path.includes(item.path)"
          :key="index"
          :src="item.image"
        />
      </template>
      <p>{{ translateTitle($route.query.title) }}</p>
    </div>
    <Form
      :key="value"
      :column="column"
      :label-width="130"
      :query="params"
      @handleCheckLink="handleCheckLink"
      @handleImageUrl="handleImageUrl"
      @handleVideoUrl="handleVideoUrl"
    />
    <div class="post-btn">
      <el-button
        :loading="loading"
        size="medium"
        type="primary"
        @click="onSubmit"
      >
        {{ translateTitle('保存') }}
      </el-button>
      <el-button size="medium" @click="cancel">
        {{ translateTitle('取消') }}
      </el-button>
    </div>
    <CheckLinkDialog ref="checklink" @handleLink="handleLink" />
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapActions } from 'vuex'
  import config from '../config'
  import Form from '@/components/Form'
  import { getloadAd, saveAd } from '@/api/operate'
  import CheckLinkDialog from '@/components/JumpLink/checkLinkDialog.vue'
  export default {
    components: {
      Form,
      CheckLinkDialog,
    },
    props: {
      column: {
        type: Array,
        default: () => [],
      },
      fromQuery: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        ...config,
        params: this.fromQuery,
      }
    },
    created() {
      if (this.$route.query.id) this.getloadAd()
    },
    methods: {
      translateTitle,
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async cancel() {
        await this.delVisitedRoute(this.$route.path)
        this.$router.go(-1)
      },
      async getloadAd() {
        const params = { id: this.$route.query.id }
        const data = await getloadAd(params)
        if (data.success) {
          this.params.video = data.data.video
          for (let el of this.column) {
            if (el.prop == 'begin_time' || el.prop == 'end_time') {
              this.params[el.prop] = data.data[el.prop] * 1000
            } else if (el.prop == 'is_show') {
              this.params[el.prop] = data.data.close == 1 ? 1 : 0
            } else if (el.prop == 'video_url') {
              this.params[el.prop] =
                data.data.videoList.length != 0
                  ? data.data.videoList[0][el.prop]
                  : null
            } else if (el.prop == 'class_id') {
              this.params[el.prop] =
                data.data[el.prop] == 0 ? null : data.data[el.prop]
            } else if (el.prop == 'audit_config') {
              if (data.data[el.prop]) {
                for (let key in data.data[el.prop]) {
                  if (key == 'ac_op') {
                    this.params[key] = Number(data.data[el.prop][key])
                  } else {
                    if (data.data[el.prop][key] === true) {
                      this.params.audit_config.push(key)
                    }
                  }
                }
              } else {
                this.params.ac_op = -1
              }
            } else {
              this.params[el.prop] = data.data[el.prop]
            }
          }
          // console.log(this.params)
        } else {
          this.$message.error('查询失败')
        }
      },
      handleCheckLink() {
        this.$refs.checklink.handleOpen()
      },
      // 地址
      handleLink(link) {
        this.params.link = link
      },
      // 获取图片上传地址
      handleImageUrl(prop, id) {
        this.params[prop] = id
      },
      // 获取视频上传地址
      handleVideoUrl(prop, query) {
        this.params[prop] = query.pic_url
        this.params['video'] = query.video_aid
        this.params['video_url'] = query.video_url
      },
      async onSubmit() {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.params))
        if (this.$route.query.id) params.id = this.$route.query.id
        // 天气广告->配置项
        if (this.params.audit_config) {
          params.audit_config = {}
          params.audit_config.ac_op = params.ac_op
          for (let item of this.phoneConfigList) {
            if (this.params.audit_config.includes(item.id)) {
              params.audit_config[item.id] = true
            } else {
              params.audit_config[item.id] = false
            }
          }
          delete params.ac_op
        }
        params.begin_time = params.begin_time / 1000
        params.end_time = params.end_time / 1000
        params.place = this.$route.query.place
        const data = await saveAd(params)
        this.loading = false
        if (data.success) {
          this.$message.success('保存成功')
          this.cancel()
        } else {
          this.$message.error(data.msg)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.post';
  #{$base}-container {
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-advertising-space {
      margin: 20px 0;
      span {
        float: left;
        width: 110px;
      }
      p {
        width: 160px;
        margin-left: 110px;
        text-align: center;
      }
      ::v-deep .el-image {
        display: table-cell;
        width: 160px;
        height: 68px;
        text-align: center;
        vertical-align: middle;
        background-color: #f5f5f5;
        img {
          width: auto;
          height: auto;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 110px;
    }
  }
</style>
